import styled, { css } from 'styled-components';

interface ICheckButton {
  checked: boolean;
}

export const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  button:last-child {
      align-self: flex-end;
    }
`;

export const ContainerNumbers = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;

  @media only screen and (max-width: 460px) {
    flex-direction: row;
    gap: 16px;
  }
`;

export const NumericContainer = styled.div<ICheckButton>`
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 13px;
  margin-bottom: ${({ checked }) => checked ? '26px' : '69px'};

  @media only screen and (min-width: 460px) {
    flex-direction: row;
    gap: 18px;
    margin-bottom: ${({ checked }) => checked ? '31px' : '69px'};
  }
`;

export const NumberItem = styled.p<ICheckButton>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.colors.gray500};

  ${props =>
    props.checked &&
    css`
      border-radius: 50%;
      position: relative;
      border: 1px solid
        ${({ theme }) => theme.colors.primary};
      ::after {
        content: '';
        position: absolute;
        rotate: 90deg;
        top: 4px;
        left: 42px;
        display: block;
        height: 25px;
        width: 1px;
        background-color: ${({ theme }) => theme.colors.primary};
      }

      @media only screen and (min-width: 460px) {
        ::after {
          rotate: initial;
          top: 40px;
          left: 18.5px;
        }
    `}

  @media only screen and (min-width: 460px) {
    width: 40px;
    height: 40px;
  }
`;

export const CheckButton = styled.div<ICheckButton>`
  height: 16px;
  width: 16px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 50%;

  ${props =>
    props.checked &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      border: 1px solid
        ${({ theme }) => theme.colors.primary};
    `}

  @media only screen and (min-width: 460px) {
    height: 18px;
    width: 18px;
  }
`;
