import styled from 'styled-components';

interface IContainerProp {
  isDraft: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;

  img {
    max-width: 200px;
    max-height: 80px;

    @media only screen and (max-width: 460px) {
      max-width: 120px;
      max-height: 48px;
    }
  }

  @media only screen and (max-width: 460px) {
    gap: 24px;
    padding: 16px;
  }
`;

export const ContainerWrapper = styled.div<IContainerProp>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: ${props => props.isDraft ? 'calc(100vh - 110px)' : 'calc(100vh - 48px)'};
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media only screen and (max-width: 460px) {
    gap: 24px;
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.gray400};
  font-size: 24px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media only screen and (max-width: 460px) {
    font-size: 16px;
  }
`;

export const Message = styled.p`
  color: ${({ theme }) => theme.colors.gray400};
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: normal;

  @media only screen and (max-width: 460px) {
    font-size: 14px;
  }
`;
