import { Exclamation } from 'components/Icons';
import { DraftMessageContainer, DraftText } from './style';

interface Props {
  surveyInProgress?: boolean;
}

export const DraftMessage = ({
  surveyInProgress = true,
}: Props) => {
  return (
    <DraftMessageContainer surveyInProgress={surveyInProgress}>
      <Exclamation className="exclamation-icon" />
      <DraftText>Este é um formulário de visualização e não coletará respostas. Altere o status da sua avaliação para ‘Execução’ e comece a receber respostas.</DraftText>
    </DraftMessageContainer>
  );
};
