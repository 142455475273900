import styled, { css } from 'styled-components';

interface ICheckButton {
  checked: boolean;
  rounded: string;
}

interface IInputError {
  inputError: boolean;
}

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 16px;
  overflow: hidden;

  @media only screen and (min-width: 460px) {
    min-height: 40px;
    gap: 24px;
  }
`;

export const TextArea = styled.textarea`
  border-radius: 4px;
  font-size: 14px;
  padding: 8px 0;
  font-weight: 400;
  line-height: 21px;
  font-style: normal;
  color: ${({ theme }) => theme.colors.gray500};
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  resize: none;
  border: none;
  outline: none; 
  overflow: hidden;
  height: 40px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray300};
  }

  @media only screen and (min-width: 460px) {
    font-size: 16px;
    line-height: normal;
  }
`;

export const AlternativeTitle = styled.h3`
  color: ${({ theme }) => theme.colors.gray500};
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;

  @media only screen and (min-width: 460px) {
    font-size: 16px;
  }
`;

export const TextAreaContainer = styled.div<IInputError>`
  display: flex;
  align-items: center;
  width: fit-content;
  position: relative;
  margin-left: 28px;
  border-bottom: 1px solid ${({ theme, inputError }) => inputError ? theme.colors.statusError : theme.colors.gray200};
  overflow-x: hidden;
  width: 85%;

  @media only screen and (min-width: 375px) {
    width: 90%;
  }

  @media only screen and (min-width: 769px) {
    width: 600px;
    margin-left: 39px;
  }
`;

export const CheckButton = styled.div<ICheckButton>`
  height: 16px;
  width: 16px;
  min-width: 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  border-radius: ${({ rounded }) => rounded};

  .selected-icon {
    display: ${({ checked }) => checked ? 'block' : 'none'};
    path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  };
  
  ${props =>
    props.checked &&
    css`
      border: none;
  `}

  @media only screen and (min-width: 460px) {
    height: 24px;
    width: 24px;
    min-width: 24px;
  }
`;

export const InputErrorMsg = styled.span`
  font-family: "Open Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.colors.statusError};
  margin-top: 8px;
  margin-left: 40px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  gap: 16px;
`;
