import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 32px 32px;

  @media only screen and (max-width: 460px) {
    padding: 0 16px;
    margin-top: 26px;
  }
`;
