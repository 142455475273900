import styled, { css } from 'styled-components';

interface IButton {
  customButton?: 'outline' | 'default';
}

export const Container = styled.button<IButton>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  height: 41px;
  cursor: pointer;
  padding: 0 32px;
  border-radius: 5px;

  @media only screen and (max-width: 460px) {
    width: 100%;
  }

  ${props =>
    props.customButton === 'outline'
      ? css`
          background-color: transparent;
          border: 1px solid ${({ theme }) => theme.colors.primary};
          color: ${({ theme }) => theme.colors.gray400};
        `
      : css`
          background-color: ${({ theme }) => theme.colors.primary};
          color: ${({ theme }) => theme.colors.white};
        `}
`;
