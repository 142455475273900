import styled, { css } from 'styled-components';

interface NumberInputProps {
  withError?: boolean
}

export const Container = styled.div`
  & label {
    color: ${({ theme: { colors } }) => colors.gray400};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
    display: block;

    @media only screen and (min-width: 460px) {
      font-size: 16px;
    }
  }

`;

export const Wrapper = styled.div`
  display: flex;
  max-width: 100%;
  height: 48px;
  position: relative;

  @media only screen and (min-width: 460px) {
    max-width: 250px;
  }
`;

export const NumberInput = styled.input<NumberInputProps>`
  outline: none;
  border: 1px solid ${({ theme: { colors } }) => colors.gray200};
  border-radius: 4px;
  padding: 16px;
  background: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  color: ${({ theme: { colors } }) => colors.gray500};

  ::placeholder {
    color: ${({ theme: { colors } }) => colors.gray300}
  }

  & {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  ${({ withError, theme: { colors } }) => withError && css`
    border: 1px solid ${colors.statusError};
  `};

  @media only screen and (min-width: 460px) {
    font-size: 16px;
  }
`;

export const ClearIcon = styled.button`
  color: ${({ theme }) => theme.colors.gray400};
  position: absolute;
  right: 5px;
  top: 2px;
`;

export const SpanError = styled.span`
  font: normal 400 12px/12px 'Open Sans';
  color: ${({ theme }) => theme.colors.statusError};
  margin-top: 8px;
`;

export const InputErrorMsg = styled.span`
  font-family: "Open Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.colors.statusError};
  margin-top: 8px;
`;
